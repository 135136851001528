function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Teknologiforståelse</span>
			<p>Teknologi er kult. Faget teknologiforståelse lærer deg hvordan teknologi fungerer. Du får lære om maskinvare som CPU, RAM, grafikkort, hovedkort og mer. Du får lage dine egne virtuelle maskiner og servere. Du får lære smarte tips og triks i Windows og hvordan du bruker Word og Excel. Lær hvordan DHCP, DNS og Active Directory fungerer.</p>
		</div>
	);
}

export default Component;
