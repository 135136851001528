function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Driftsstøtte</span>
			<p>Mange bedrifter og offentlige steder har IT utstyr. Alt dette skal vedlikeholdes. Nesten alle apper eller nettsider du bruker er koblet til en server. Noen må sørge for at denne fungerer som den skal. I faget driftsstøtte lærer du dette. Du får lære om delene i en datamaskin, hvordan nettverk fungerer og om datasikkerhet. Lag din egen virtuelle datamaskin og server. </p>
		</div>
	);
}

export default Component;
