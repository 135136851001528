import WhyIm from './WhyIm.jsx';
import YouShouldBe from './YouShouldBe.jsx';
import FutureLearning from './FutureLearning.jsx';
/* This example requires Tailwind CSS v2.0+ */
import { MailIcon } from '@heroicons/react/outline';

const people = [
	{
		name: 'Margareth Rimmereide',
		role: 'Kontaktlærer VG1',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'margareth.rimmereide@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Guro Brudvik Dalene',
		role: 'Kontaktlærer VG1',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'guro.brudvik.dalene@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Lars Valen',
		role: 'Lærer',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'lars.valen@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Per Berg',
		role: 'Kontaktlærer VG2',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'per.berg@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Sturle Stiansen',
		role: 'Kontaktlærer VG2',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'sturle.stiansen@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Samuel Berhe Tsaedu',
		role: 'Lærer',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'samuel.berhe.tsaedu@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	{
		name: 'Staale Andre Bergersen',
		role: 'Lærer',
		imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
		email: 'staale.bergersen@vtfk.no',
		badges: [{ name: 'Utvikling', color: 'red' }],
	},
	// More people...
];

function Page() {
	return (
		<div className="flex flex-col gap-16 mb-24 pt-6 mx-5 sm:mx-0 ">
			<WhyIm />
			<a href="https://www.instagram.com/im_porsgrunn/" className="text-2xl md:text-4xl font-extrabold block text-rose-500 mb-3">
				Intressert? Har du spørsmål? Send oss en melding på instagram!
			</a>
			<YouShouldBe />
			<FutureLearning />
			<div className="space-y-12">
				<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
					<h2 className="text-2xl font-extrabold tracking-tight text-rose-500">Lærerne våre</h2>
					<p className="text-xl text-gray-500">Har du noen spørsmål om IM? Kontakt en av lærene våre!</p>
				</div>
				<ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
					{people.map((person) => (
						<li key={person.name}>
							<div className="space-y-4">
								<div className="flex flex-row gap-4 justify-between items-center">
									<div className="text-lg leading-6 font-medium space-y-1">
										<h3>{person.name}</h3>
										<p className="text-indigo-600 dark:text-indigo-500">{person.role}</p>
									</div>
									<a href={'mailto: ' + person.email} className="text-gray-400 hover:text-indigo-500 self-start">
										<span className="sr-only">Email</span>
										<MailIcon className="h-6" />
									</a>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Page;
