import WelcomeToIm from './WelcomeToIm.jsx';
import { NavLink } from 'react-router-dom';

function Page() {
	return (
		<div className="flex flex-col gap-20 mb-20">
			<div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
				<div className="absolute inset-0">
					<img className="h-full w-full object-cover" src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops" />
					<div className="absolute inset-0 bg-gradient-to-r from-blue-700 via-green-700 to-amber-700 mix-blend-multiply" />
				</div>
				<div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
					<h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
						<span className="block text-white">IM</span>
						<span className="block text-rose-500">PORSGRUNN</span>
					</h1>
					<p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">IM Porsgrunn er et yrkesfaglig utdanningsløp ved Porsgrunn Videregående Skole. Her lærer elevene om både IT og medier.</p>
					<div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
						<div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
							<NavLink to="/om" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-rose-600 shadow-rose-600/40 shadow-md hover:bg-rose-700 sm:px-8">
								Fortell meg mer
							</NavLink>
							<a href="https://www.instagram.com/im_porsgrunn/" className="flex items-center justify-center px-4 py-3 text-base font-medium rounded-md text-rose-600 dark:text-white dark:bg-slate-700 dark:shadow-slate-600/30 dark:hover:bg-slate-600 bg-white shadow-md shadow-white/20 hover:bg-gray-200 sm:px-8">
								Instagram
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col mx-5 sm:mx-0 gap-24">
				<WelcomeToIm />
			</div>
		</div>
	);
}

export default Page;
