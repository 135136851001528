import HomePage from './components/HomePage.jsx';
import AboutPage from './components/AboutPage.jsx';
import SubjectsPage from './components/SubjectsPage.jsx';
import QuizPage from './components/QuizPage.jsx';
import './App.css';
import React, { Fragment } from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import LogoIM from './assets/ImLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

const navigation = [
	{ name: 'Hjem', url: '/' },
	{ name: 'Om IM', url: '/om' },
	{ name: 'Fag', url: '/fag' },
];

function App() {
	if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
		document.documentElement.className = 'dark bg-neutral-100 dark:bg-slate-900';
	} else {
		document.documentElement.className = 'bg-neutral-100 dark:bg-slate-900';
	}

	function AddDarkMode() {
		// Whenever the user explicitly chooses dark mode
		localStorage.theme = 'dark';
		document.documentElement.className = 'dark bg-neutral-100 dark:bg-slate-900';
	}

	function AddLightMode() {
		// Whenever the user explicitly chooses light mode
		localStorage.theme = 'light';
		document.documentElement.className = 'bg-neutral-100 dark:bg-slate-900';
	}

	return (
		<div className="bg-neutral-100 dark:bg-gray-900 dark:text-slate-400  min-h-screen overflow-x-hidden">
			<div className="relative min-h-screen max-w-7xl mx-auto sm:px-6 lg:px-8 flex flex-col">
				<Popover>
					{({ open }) => (
						<header className="grow-0">
							<div className="py-6 px-7 sm:px-0">
								<nav className="relative flex items-center justify-between sm:h-10 lg:justify-center" aria-label="Global">
									<div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
										<div className="flex items-center justify-between w-full lg:w-auto">
											<NavLink to="/">
												<span className="sr-only">Logo for IM Porsgrunn</span>
												<img className="h-8 w-auto sm:h-10" src={LogoIM} alt="" />
											</NavLink>
											<div className="-mr-2 flex items-center lg:hidden">
												<button onClick={AddLightMode} className="hidden mr-2 p-2 dark:inline-flex items-center justify-center text-slate-300 hover:text-slate-100 ">
													<FontAwesomeIcon className="grow-0 h-6 text-slate-500 hover:text-slate-400" icon={solid('lightbulb')} />
												</button>
												<button onClick={AddDarkMode} className="dark:hidden mr-2 p-2 inline-flex items-center justify-center text-slate-300 hover:text-slate-100 ">
													<FontAwesomeIcon className="grow-0 h-6 text-slate-400 hover:text-slate-600" icon={regular('lightbulb')} />
												</button>
												<Popover.Button className="bg-gray-200 dark:bg-slate-700 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-1000 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
													<span className="sr-only">Åpne hovedmeny</span>
													<MenuIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
									</div>
									<nav className="hidden lg:flex lg:space-x-6">
										{navigation.map((item) => (
											<NavLink to={item.url} key={item.name} className={({ isActive }) => 'px-3 py-2 rounded-md text-sm font-medium ' + (isActive ? '  ring-2 ring-rose-500 text-black dark:text-slate-100 shadow-md shadow-rose-500/50' : '  ring-2 ring-neutral-300 shadow-md shadow-neutral-300/50 hover:ring-rose-300 hover:shadow-rose-300/50 text-black dark:ring-slate-700 dark:shadow-slate-700/50 dark:hover:ring-rose-500 dark:hover:shadow-rose-500/50 dark:text-slate-100')}>
												{item.name}
											</NavLink>
										))}
										<a href="https://www.instagram.com/im_porsgrunn/" key="instagram" className="px-3 py-2 rounded-md text-sm font-medium ring-2 ring-neutral-300 shadow-md shadow-neutral-300/50 hover:ring-rose-300 hover:shadow-rose-300/50 text-black dark:ring-slate-700 dark:shadow-slate-700/50 dark:hover:ring-rose-500 dark:hover:shadow-rose-500/50 dark:text-slate-100">
											Instagram
										</a>
									</nav>
									<div className="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
										<span className="inline-flex rounded-md shadow">
											<NavLink to="/quiz" className="inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-white bg-rose-600 shadow-rose-600/40 shadow-md hover:bg-rose-700">
												Ta IM-quizen
											</NavLink>
										</span>
										<button onClick={AddLightMode} className="hidden ml-2 p-2 dark:inline-flex items-center justify-center text-slate-300 hover:text-slate-100 ">
											<FontAwesomeIcon className="grow-0 h-6 text-slate-500 hover:text-slate-400" icon={solid('lightbulb')} />
										</button>
										<button onClick={AddDarkMode} className="dark:hidden ml-2 p-2 inline-flex items-center justify-center text-slate-300 hover:text-slate-100 ">
											<FontAwesomeIcon className="grow-0 h-6 text-slate-400 hover:text-slate-600" icon={regular('lightbulb')} />
										</button>
									</div>
								</nav>
							</div>

							<Transition show={open} as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
								<Popover.Panel focus static className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
									<div className="rounded-lg shadow-md bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
										<div className="px-5 pt-4 flex items-center justify-between">
											<div>
												<img className="h-8 w-auto" src={LogoIM} alt="" />
											</div>
											<div className="-mr-2">
												<Popover.Button className="bg-white dark:bg-slate-700 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-1000 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
													<span className="sr-only">Lukk hovedmeny</span>
													<XIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
										<nav className="px-2 pt-2 pb-3">
											{navigation.map((item) => (
												<a key={item.name} href={item.url} className="block px-3 py-2 rounded-md text-base font-medium dark:text-slate-400 text-gray-700 hover:text-gray-900 hover:bg-gray-100 dark:hover:text-slate-300 dark:hover:bg-slate-700">
													{item.name}
												</a>
											))}
											<a key="instragram" href="https://www.instagram.com/im_porsgrunn/" className="block px-3 py-2 rounded-md text-base font-medium dark:text-slate-400 text-gray-700 dark:hover:text-slate-300 dark:hover:bg-slate-700 hover:text-gray-900 hover:bg-gray-100">
												Instagram
											</a>
										</nav>
										<NavLink to="/quiz" className="block w-full px-5 py-3 text-center font-medium dark:bg-slate-700 dark:font-bold dark:text-rose-500 text-rose-600 bg-gray-100 hover:bg-gray-200">
											Ta IM-quizen
										</NavLink>
									</div>
								</Popover.Panel>
							</Transition>
						</header>
					)}
				</Popover>

				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="om" element={<AboutPage />} />
					<Route path="fag" element={<SubjectsPage />} />
					<Route path="quiz" element={<QuizPage />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
