function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Brukerstøtte</span>
			<p>Det blir bare mer og mer IT rundt oss. For mange kan det være vanskelig å utføre enkle oppgaver på datamaskin eller nettbrett. I fager brukerstøtte lærer du hvordan du kan hjelpe og veilede andre. Du lærer om viktige retningslinjer og lovverk. Du lærer hvordan du gir support, hvordan du holder kurs eller hvordan du lager brukerveiledninger.</p>
		</div>
	);
}

export default Component;
