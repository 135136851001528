function Component() {
	return (
		<div className="">
			<span className="text-2xl font-extrabold block text-rose-500 mb-3">Videre utdanning:</span>
			<p>
				<ul className="list-disc list-inside leading-relaxed text-base">
					<li>Du kan ta videre utdanning på fagskole. En fagskoleutdanning er en kort og yrkesrettet videreutdanning som er beregnet på de som har gått yrkesfag.</li>
					<li>Du kan ta Vg3 påbygging til generell studiekompetanse. Da har du mulighet til å ta høyere utdanning ved høyskole eller universitet.</li>
					<li>Du kan også søke opptak til høyere utdanning etter forkurs eller gjennom Y-veien, yrkesfaglig vei til høyere utdanning. Y-veien er beregnet på deg som har relevant yrkeskompetanse.</li>
				</ul>
			</p>
		</div>
	);
}

export default Component;
