/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline';

const features = [
	{
		name: 'Yrkesfaglig',
		description: 'Informasjonsteknologi og medieproduksjon er en yrkesfaglig linje. Det betyr at du har mindre fellesfag og mer programfag.',
	},
	{
		name: 'Lærling',
		description: 'Når du har fullført VG1 og VG2 så kan du bli lærling i 2 år. Som lærling får du lønn, selv om det er en del av opplæringen.',
	},
	{
		name: 'Fagbrev',
		description: 'Etter du har vært lærling i 2 år så kan du ta fagbrev. Det gir deg mulighet til å komme deg raskt ut i jobb.',
	},
	{
		name: 'Drift',
		description: 'Bli en ekspert på datamaskiner og servere. Lær hvordan du monterer, reparerer og demonterer datamaskiner og servere.',
	},
	{
		name: 'Utvikling',
		description: 'Lag kule nettsider og lær hvordan tjenester du bruker hver dag er bygget. Løs problemer og automatiser med kode.',
	},
	{
		name: 'Mediedesign',
		description: 'Lær hvordan du designer plakater, videoer og mer. Finn ut hvordan du tar bilder som om du var profesjonell.',
	},
	{
		name: 'Godt miljø',
		description: 'Møt andre med samme interesse som deg, og vær deg selv. Få god hjelp av både lærere og elever. Bare spør!',
	},
	{
		name: 'Kantina',
		description: 'Her på Porsgrunn Videregående Skole har vi en kantine full av variert og deilig mat. Maten er fantastisk, prøv selv!',
	},
];

function Component() {
	return (
		<div className="">
			<div className="max-w-3xl text-left">
				<h2 className="text-2xl font-extrabold block text-rose-500">Hvorfor IM?</h2>
				<p className="mt-4 text-lg text-gray-500">Usikker på om IM er noe for deg? Her ser du hvorfor du bør gå på IM. </p>
			</div>
			<dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
				{features.map((feature) => (
					<div key={feature.name} className="relative">
						<dt>
							<CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
							<p className="ml-9 text-lg leading-6 font-medium text-gray-900 dark:text-slate-400">{feature.name}</p>
						</dt>
						<dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
					</div>
				))}
			</dl>
		</div>
	);
}

export default Component;
