function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Produksjon og historiefortelling</span>
			<p>Vær kreativ! I faget produksjon og historiefortelling får du muligheten til å bruke speilreflekskamera til å ta bilder og film. Du får produsere og designe plakater, blader, logo og mer. Du lærer om font og farge. Du blir kjent med ulike Adobe programmer slik som Adobe Photoshop. </p>
		</div>
	);
}

export default Component;
