function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">IT-utviklerfaget</span>
			<p>Skaff deg en egen lærlingplass. Dette er starten på arbeidslivet. Du jobber i 2 år, så kan du melde deg opp til fagprøve og ta fagbrev. Vet du hva det beste av alt er? Du får lærlinglønn! Når du er lærling i IT-utviklerfaget så kan du jobbe på flere steder. Du får jobbe med programmering av applikasjoner, nettsteder og mer! Du får muligheten til å utforske nye teknologier. </p>
		</div>
	);
}

export default Component;
