function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Konseptutvikling og programmering</span>
			<p>I dette faget lærer du hvordan du skriver og strukturerer HTML, endrer utsende ved hjelp av CSS og bruker JavaScript for å utføre forskjellige handlinger. Du gjør nettet til et bedre sted ved å lære om viktigheten av universell utforming, rettigheter og copyright. Du får utvikle konsepter og lære om problemløsning.</p>
		</div>
	);
}

export default Component;
