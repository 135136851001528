import Development from './Development.jsx';
import Operation from './Operation.jsx';
import Support from './Support.jsx';
import Production from './Production.jsx';
import TechnologyUnderstanding from './TechnologyUnderstanding.jsx';
import ConceptDevelopmentProgramming from './ConceptDevelopmentProgramming.jsx';
import OperationsTraniee from './OperationsTraniee';
import DevelopmentTraniee from './DevelopmentTraniee';

function Page() {
	return (
		<div className="flex flex-col gap-24 mb-24 pt-6 mx-5 sm:mx-0 ">
			<div>
				<span className="text-2xl font-extrabold block text-rose-500">Informasjonsteknologi og Medieproduksjon (VG1)</span>
				<dl className="mt-4 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-12 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
					<div className="sm:col-span-2 lg:col-span-1">
						<TechnologyUnderstanding />
					</div>
					<div>
						<Production />
					</div>
					<div>
						<ConceptDevelopmentProgramming />
					</div>
				</dl>
			</div>
			<div>
				<span className="text-2xl font-extrabold block text-rose-500">Informasjonsteknologi (VG2)</span>
				<dl className="mt-4 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
					<div className="sm:col-span-2 lg:col-span-1">
						<Support />
					</div>
					<div>
						<Development />
					</div>
					<div>
						<Operation />
					</div>
				</dl>
			</div>
			<div>
				<span className="text-2xl font-extrabold block text-rose-500">Lærling (VG3)</span>
				<dl className="mt-4 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-8">
					<div>
						<OperationsTraniee />
					</div>
					<div>
						<DevelopmentTraniee />
					</div>
				</dl>
			</div>
		</div>
	);
}

export default Page;
