function Component() {
	return (
		<div className="">
			<span className="text-2xl font-extrabold block text-rose-500 mb-3">Velkommen til oss!</span>
			<p>
				Velkommen til oss her på IM! Om du velger å gå her hos oss så får du muligheten til å lære IT-drift, IT-utvikling og medieproduksjon. Når du kommer til VG2 så kan du fordype deg i enten IT eller medier. Porsgrunn Videregående Skole tilbyr kun VG2 informasjonsteknologi, men du kan finne skoler som tilbyr{' '}
				<a href="https://www.vilbli.no/nb/nb/vestfold-og-telemark/skoler-og-laerebedrifter/program/v.im/v.imikm1----_v.immed2----_/p5" className="font-bold underline decoration-indigo-500 decoration-solid decoration-2">
					VG2 medieproduksjon på vilbli.no
				</a>
				. Når du har fullført VG1 og VG2 kan du bli lærling og ta fagbrev innen informasjonsteknologi eller medieproduksjon.{' '}
			</p>
		</div>
	);
}

export default Component;
