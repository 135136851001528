function Component() {
	return (
		<div className="">
			<span className="text-2xl font-extrabold block text-rose-500 mb-3">Du bør være:</span>
			<p>
				<ul className="list-disc list-inside leading-relaxed text-base">
					<li>Kreativ</li>
					<li>Intressert i IT, nettverk og programvare</li>
					<li>Intressert i grafisk design</li>
					<li>Glad i å hjelpe andre</li>
					<li>Være god på problemøsning</li>
					<li>Nøyaktig</li>
					<li>Intressert i nye teknologier</li>
				</ul>
			</p>
		</div>
	);
}

export default Component;
