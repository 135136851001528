function Component() {
	return (
		<div className="relative">
			<span className="text-xl font-bold block text-indigo-700 dark:text-indigo-500 mb-3">Utvikling</span>
			<p>Utvikling! Her knaser hjernen som bare det. Du får jobbe med å utvikle nye ting. Du lærer hvordan du kan bruke programmering til å løse ulike problemer. Du lærer om forskjellige løsninger og lagring av data. Kanskje du vil lage din helt egen nettside? Eller kode som automatiserer noe? Fantasien er det eneste som setter grenser.</p>
		</div>
	);
}

export default Component;
