import { useState } from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

function Page() {
	const quizTemplate = {
		current: 1,
		show: 1,
		mediaPointsTotal: 0,
		itPointsTotal: 0,
		questions: [
			{
				question: 'Er du interessert i koding?',
				answers: [
					{ answer: 'Ja! Jeg kan allerede kode.', icon: regular('file-code'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Ja, men kan ikke kode.', icon: regular('keyboard'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Nei, der høres ikke gøy ut.', icon: regular('face-meh'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Liker du å ta bilder?',
				answers: [
					{ answer: 'Jeg gjør det hele tiden!', icon: regular('images'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Gjør det ikke så ofte, men har lyst til å lære om det.', icon: regular('note-sticky'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Det er så kjedelig å ta bilder.', icon: regular('circle-question'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du kreativ?',
				answers: [
					{ answer: 'Ja, jeg er superkreativ! Jeg har mange gode ideer.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 2 },
					{ answer: 'Jeg er litt kreativ.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Nei, ingen kreativitet hos meg.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Synes du det er gøy å redigere bilder?',
				answers: [
					{ answer: 'Ja. Det er spennende, har gjort det mange ganger.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Ja, har gjort det av og til. Vil gjerne lære mer.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Nei, det høres ikke gøy ut.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du glad i å planlegge?',
				answers: [
					{ answer: 'Ja, jeg er en planleggingsekspert!', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 2 },
					{ answer: 'Planlegger litt.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Nei, hva er poenget med å planlegge?', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Hva synes du om markedsføring?',
				answers: [
					{ answer: 'Det er kult!', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Helt greit.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Synes det er noe dritt.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Liker du å skrive historier eller fortellinger?',
				answers: [
					{ answer: 'Ja. Gjør det på fritiden.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Det går fint, men er ikke det beste jeg vet.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Huff, nei.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Liker du å lage bilder eller videoer?',
				answers: [
					{ answer: 'Ja. Gjør det hele tiden.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Ganske gøy det, gjør det av og til.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Nei, det er så kjedelig.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Har du hørt om universal utforming?',
				answers: [
					{ answer: 'Ja, det er superviktig.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Nei, men det høres spennende ut.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Nei. Bryr meg ikke om det.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du nøyaktig?',
				answers: [
					{ answer: 'Ja, alltid.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 2 },
					{ answer: 'Ja, for det meste.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Egentlig ikke.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Liker du grafisk design?',
				answers: [
					{ answer: 'Ja. Det høres ganske gøy ut.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 0 },
					{ answer: 'Kanskje?', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 0 },
					{ answer: 'Nei takk.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Har du bygget en PC før?',
				answers: [
					{ answer: 'Ja. Det er gøy.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Nei. Har lyst til å lære om det.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Det høres ikke gøy ut.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du god i programvare som Word, Excel og PowerPoint?',
				answers: [
					{ answer: 'Ja. Jeg kan kjempe mye.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Ja, er ganske god.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Er dårlig på det.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er det viktig å lære om etikk, lovverk og personvern?',
				answers: [
					{ answer: 'Ja, det er veldig viktig.', icon: regular('lightbulb'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Nei, hvem bryr seg?', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Synes du ny teknologi er spennende?',
				answers: [
					{ answer: 'Ja. Følger alltid med på nye ting.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Ja, er ganske spennende.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Tja, holder meg til det jeg kan.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er god på å fikse problemer?',
				answers: [
					{ answer: 'Ja. Prøver alltid å løse det selv om jeg kanskje feiler. ', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Ikke den beste, men prøver av og til.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Nei, fikser aldri ting selv.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du god på å gi andre opplæring?',
				answers: [
					{ answer: 'Ja. Føler meg god på det.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Kanskje, vet ikke helt.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Nei, er dårlig på å lære bort.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du opptatt av design (typografi, layout, virkemidler og farger)?',
				answers: [
					{ answer: 'Ja. Synes det er viktig og gøy. Ting må se bra ut.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 2 },
					{ answer: 'Litt? Er ikke så nøye.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Det er ikke viktig.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Har du tenkt på hvordan medier kan påvirke mennesker?',
				answers: [
					{ answer: 'Ja. Medier påvirker oss ganske mye.', icon: regular('lightbulb'), mediaPoints: 2, itPoints: 2 },
					{ answer: 'Har hørt at medier påvirker, men har ikke tenkt noe på det.', icon: regular('pen-to-square'), mediaPoints: 1, itPoints: 1 },
					{ answer: 'Nei, det er bare drama. Er ikke så ille.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Vil du lære hvordan internett funker?',
				answers: [
					{ answer: 'Ja. Det høres spennende ut.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Er det så viktig? Er vel greit så lenge det funker.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Er du god på å lese bruksanvisninger/dokumentasjon?',
				answers: [
					{ answer: 'Ganske god. Finner ofte løsninger på problemet selv.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 2 },
					{ answer: 'Nei, må ofte spørre andre om hjelp.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},
			{
				question: 'Hva er ruter, svitsj og aksesspunkt?',
				answers: [
					{ answer: 'Nettverkskomponenter.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 1 },
					{ answer: 'Serverdeler.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 0 },
					{ answer: 'Deler til prosessoren.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 0 },
				],
				userAnswer: null,
			},

			{
				question: 'Hva er HTML, CSS, JavaScript, Python og SQL?',
				answers: [
					{ answer: 'Skjerminnstillinger.', icon: regular('lightbulb'), mediaPoints: 0, itPoints: 0 },
					{ answer: 'PC-deler.', icon: regular('pen-to-square'), mediaPoints: 0, itPoints: 0 },
					{ answer: 'Kodespråk.', icon: regular('face-dizzy'), mediaPoints: 0, itPoints: 1 },
				],
				userAnswer: null,
			},
		],
	};
	const [quizInfo, setQuizInfo] = useState(quizTemplate);
	const [quizResults, setQuizResults] = useState({
		maxITPoints: 0,
		maxMediaPoints: 0,
		totalITPoints: 0,
		totalMediaPoints: 0,
		maxPoints: 0,
		totalPoints: 0,
		precentageITPoints: 0,
		precentageMediaPoints: 0,
		precentageTotalPoints: 0,
	});

	function Test() {
		var newQuizInfo = { current: 0, show: quizInfo.show };
		setQuizInfo({
			...quizInfo,
			...newQuizInfo,
		});
		setTimeout(function () {
			//Start the timer
			var newQuizInfo = { current: -1, show: quizInfo.show + 1 };
			setQuizInfo({
				...quizInfo,
				...newQuizInfo,
			});

			if (quizInfo.show - 1 === quizTemplate.questions.length) {
				var totalITPoints = 0;
				var totalMediaPoints = 0;
				var maxITPoints = 0;
				var maxMediaPoints = 0;
				console.log(quizInfo);
				for (var qidx = 0; qidx < quizInfo.questions.length; qidx++) {
					var maxQuestionITPoints = 0;
					var maxQuestionMediaPoints = 0;
					for (var aidx = 0; aidx < quizInfo.questions[qidx].answers.length; aidx++) {
						if (quizInfo.questions[qidx].answers[aidx].mediaPoints > maxQuestionMediaPoints) {
							maxQuestionMediaPoints = quizInfo.questions[qidx].answers[aidx].mediaPoints;
						}
						if (quizInfo.questions[qidx].answers[aidx].itPoints > maxQuestionITPoints) {
							maxQuestionITPoints = quizInfo.questions[qidx].answers[aidx].itPoints;
						}
					}
					totalITPoints += quizInfo.questions[qidx].answers[quizInfo.questions[qidx].userAnswer].itPoints;
					totalMediaPoints += quizInfo.questions[qidx].answers[quizInfo.questions[qidx].userAnswer].mediaPoints;
					maxITPoints += maxQuestionITPoints;
					maxMediaPoints += maxQuestionMediaPoints;
				}
				console.log(totalITPoints, totalMediaPoints, maxITPoints, maxMediaPoints);
				setQuizResults({
					maxITPoints: maxITPoints,
					maxMediaPoints: maxMediaPoints,
					totalITPoints: totalITPoints,
					totalMediaPoints: totalMediaPoints,
					maxPoints: maxITPoints + maxMediaPoints,
					totalPoints: totalITPoints + totalMediaPoints,
					precentageITPoints: Math.round((totalITPoints / maxITPoints) * 100),
					precentageMediaPoints: Math.round((totalMediaPoints / maxMediaPoints) * 100),
					precentageTotalPoints: Math.round(((totalITPoints + totalMediaPoints) / (maxITPoints + maxMediaPoints)) * 100),
				});
			}

			setTimeout(function () {
				//Start the timer
				var newQuizInfo = { current: quizInfo.show + 1, show: quizInfo.show + 1 };
				setQuizInfo({
					...quizInfo,
					...newQuizInfo,
				});
			}, 5);
		}, 300);
	}
	function Test2() {
		var newQuizInfo = { current: -1, show: quizInfo.show };
		setQuizInfo({
			...quizInfo,
			...newQuizInfo,
		});
		setTimeout(function () {
			//Start the timer
			var newQuizInfo = { current: 0, show: quizInfo.show - 1 };
			setQuizInfo({
				...quizInfo,
				...newQuizInfo,
			});
			setTimeout(function () {
				//Start the timer
				var newQuizInfo = { current: quizInfo.show - 1, show: quizInfo.show - 1 };
				setQuizInfo({
					...quizInfo,
					...newQuizInfo,
				});
			}, 5);
		}, 300);
	}

	function SetQuestionAnswer(questionIdx, answerIdx) {
		var newQuizInfo = { questions: quizInfo.questions };

		if (newQuizInfo.questions[questionIdx].userAnswer === answerIdx) {
			newQuizInfo.questions[questionIdx].userAnswer = null;
			setQuizInfo({
				...quizInfo,
				...newQuizInfo,
			});
		} else {
			newQuizInfo.questions[questionIdx].userAnswer = answerIdx;
			setQuizInfo({
				...quizInfo,
				...newQuizInfo,
			});
		}
	}

	return (
		<div className="flex grow flex-col justify-center min-h-full mx-5 sm:mx-0 max-w-full dark:text-slate-500">
			<div className="">
				<div key="1slide" className={'flex-col justify-center items-center gap-8 transition-transform duration-300 ' + (quizInfo.show === 1 ? 'flex ' : 'hidden ') + (quizInfo.current === 1 ? 'translate-x-[0%]' : (quizInfo.show === 1 && quizInfo.current === 0) || quizInfo.show > 1 ? 'translate-x-[100%]' : 'translate-x-[-100%]')}>
					<h1 className="text-center font-extrabold tracking-tight text-5xl  text-rose-500">IM-quizen</h1>
					<p className="max-w-lg mx-auto text-center text-xl dark:text-slate-400 text-gray-800 sm:max-w-1xl">Usikker på om IM passer for deg? Ta vår quiz! Svar på noen spørsmål så skal vi gi deg en anbefaling.</p>
					<button onClick={Test} className="flex max-w-max items-center justify-center gap-1 px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-rose-600 shadow-rose-600/40 shadow-md hover:bg-rose-700 sm:px-8">
						Start quizen
						<ChevronRightIcon className="h-6" />
					</button>
				</div>

				<div key={quizTemplate.questions.length + 2 + 'slide'} className={'flex-col justify-center items-center gap-8 transition-transform duration-300 ' + (quizInfo.show === quizTemplate.questions.length + 2 ? 'flex ' : 'hidden ') + (quizInfo.current === quizTemplate.questions.length + 2 ? 'translate-x-[0%]' : (quizInfo.show === quizTemplate.questions.length + 2 && quizInfo.current === 0) || quizInfo.show > quizTemplate.questions.length + 2 ? 'translate-x-[100%]' : 'translate-x-[-100%]')}>
					<h1 className="text-center font-extrabold tracking-tight text-3xl  text-rose-500">Resultater</h1>
					<FontAwesomeIcon className="grow-0 h-14 text-gray-700 dark:text-slate-500" icon={quizResults.precentageTotalPoints >= 90 ? regular('face-grin-hearts') : quizResults.precentageTotalPoints >= 80 ? regular('face-grin-stars') : quizResults.precentageTotalPoints >= 60 ? regular('face-grin-squint') : quizResults.precentageTotalPoints >= 40 ? regular('face-surprise') : quizResults.precentageTotalPoints >= 20 ? regular('face-meh-blank') : regular('face-grimace')} />
					<p className="max-w-lg mx-auto text-center text-xl text-gray-800 sm:max-w-1xl dark:text-slate-500">
						{quizResults.precentageTotalPoints >= 90 ? `Wow! Det ser ut som du kunne passa perfekt her på IM. ` : quizResults.precentageTotalPoints >= 80 ? `Ser ut som IM er noe for deg, du hadde nok kost deg her! ` : quizResults.precentageTotalPoints >= 60 ? `Det ser ut som du passer her på IM. ` : quizResults.precentageTotalPoints >= 40 ? `Kanskje du hadde passa her? Ble ikke full match men du matcher en del. ` : quizResults.precentageTotalPoints >= 20 ? `Du hadde nok ikke passa så godt inn her på IM. ` : `Ser ikke ut som IM er noe for deg, kan være lurt å tenke på en annen linje. `}
						Du har en <b>{quizResults.precentageTotalPoints}%</b> match! {quizResults.precentageMediaPoints === quizResults.precentageITPoints ? 'Du liker medieproduksjon og informasjonsteknologi like mye.' : quizResults.precentageMediaPoints > quizResults.precentageITPoints ? 'Ser ut som du liker medieproduksjon bedre enn informasjonsteknologi.' : 'Ser ut som du liker informasjonsteknologi bedre enn medieproduksjon.'}
					</p>
				</div>
				{quizTemplate.questions.map((question, questionIdx) => (
					<div key={questionIdx + 2 + 'slide'} className={'flex-col justify-center items-center gap-8 transition-transform duration-300 ' + (quizInfo.show === questionIdx + 2 ? 'flex ' : 'hidden ') + (quizInfo.current === questionIdx + 2 ? 'translate-x-[0%]' : (quizInfo.show === questionIdx + 2 && quizInfo.current === 0) || quizInfo.show > questionIdx + 2 ? 'translate-x-[100%]' : 'translate-x-[-100%]')}>
						<p className="max-w-lg mx-auto text-center text-2xl text-gray-800 dark:text-slate-300 font-bold sm:max-w-1xl">
							[{questionIdx + 1}/{quizTemplate.questions.length}] {question.question}
						</p>
						<div className="w-full max-w-lg mx-auto sm:max-w-2xl  gap-y-4 grid lg:gap-x-4">
							{question.answers.map((answer, answerIdx) => (
								<button key={answerIdx} onClick={() => SetQuestionAnswer(questionIdx, answerIdx)} className={'flex flex-row justify-center items-center gap-4 px-4 py-3 rounded-md text-sm font-medium ring-2 text-black shadow-md ' + (quizInfo.questions[questionIdx].userAnswer === answerIdx ? 'ring-rose-500 dark:ring-rose-600 shadow-rose-500/50' : 'ring-neutral-300 dark:ring-slate-500 shadow-neutral-300/50 dark:shadow-neutral-300/20 dark:hover:ring-rose-500 dark:hover:shadow-rose-500/30 hover:ring-rose-300 hover:shadow-rose-300/50')}>
									{/* <FontAwesomeIcon className="grow-0 h-6 w-7 min-w-[1.75rem] max-w-[1.75rem] text-gray-700" icon={answer.icon} /> */}
									<p className="grow text-base text-left text-gray-700 dark:text-slate-400">{answer.answer}</p>
								</button>
							))}
						</div>
						<div className="flex flex-row gap-3">
							<button onClick={Test2} className="flex max-w-mx items-center justify-center gap-1 px-3 py-2 border-2 border-gray-200 dark:border-slate-500 text-base font-medium rounded-md text-rose-600 shadow-md shadow-white/20 dark:shadow-slate-200/10 hover:border-gray-300 sm:px-6">
								<ChevronLeftIcon className="h-6" />
								Tilbake
							</button>

							<button onClick={Test} disabled={quizInfo.questions[questionIdx].userAnswer === null ? true : false} className="flex max-w-max items-center justify-center gap-1 px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-rose-600 shadow-rose-600/40 dark:shadow-rose-600/20 shadow-md hover:bg-rose-700 disabled:bg-gray-600 disabled:shadow-gray-600/40 dark:disabled:shadow-gray-600/20 sm:px-6">
								Neste
								<ChevronRightIcon className="h-6" />
							</button>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Page;
